<template>
  <b-nav-item-dropdown
    v-if="userData"
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.name || userData.email }}
        </p>
        <span
          v-if="userData.customer && isBalanceVisible && accounting[0].balance"
          class="user-status text-success"
        >
          $ {{ accounting[0].balance | money }}
        </span>
        <span v-else class="user-status text-success">
          {{ userData.role_name | establishmentRoles }}
        </span>
      </div>
      <b-avatar
        size="40"
        :src="`${userData.logo}`"
        variant="light-primary"
        badge
        class="badge-minimal"
        :badge-variant="offlineMode ? 'danger' : 'success'"
      >
        <feather-icon v-if="!userData.name" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
      <span> Configuración</span>
    </b-dropdown-item>
    <!-- <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
      <span>FAQ</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'pages-knowledge-base' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="InfoIcon" class="mr-50" />
      <span>Knowlage Base</span>
    </b-dropdown-item> -->

    <b-dropdown-divider />

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout()">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Cerrar sesión</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue"
// import { initialAbility } from '@/libs/acl/config'
import useJwt from "@/auth/jwt/useJwt"
import { avatarText } from "@core/utils/filter"
import { mapGetters } from "vuex"

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
    }
  },
  computed: {
    ...mapGetters("appConfig", ["isBalanceVisible"]),
    ...mapGetters("pos", ["offlineMode"]),
    ...mapGetters("walleats", ["accounting"]),
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem("userData")

      // Reset ability
      // this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: "auth-login" })
    },
    isOnline() {
      const x = navigator.onLine ? "success" : "danger"
      return x
    },
  },
}
</script>
